import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(3)}px`,
    backgroundColor: 'white',
  },
  title: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#282A75',
    marginBottom: theme.spacing(6),
  },
  featureImage: {
    width: '100px',
    height: '100px',
  },
  title1: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.8rem',
    fontWeight: 500,
    color: '#8A58E0',
    marginBottom: theme.spacing(3),
  },
  text: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 300,
    color: '#000000',
    marginBottom: theme.spacing(0),
  },
  section: {
    backgroundColor: 'white',
    margin: 0,
    padding: 81,
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Personalized Natal Charts",
      subtitle: "Discover the blueprint of your personality and destiny with detailed natal charts.",
      image: "/calendar.png",
    },
    {
      title: "Real-Time AI Consultations",
      subtitle: "Get instant answers to your astrological queries anytime, anywhere.",
      image: "/analysis.png",
    },
    {
      title: "Advice for Life’s Decisions",
      subtitle: "Make informed choices about relationships, career, and personal growth with guidance from the stars.",
      image: "/astronomer.png",
    },
    {
      title: "Astrological Forecasts",
      subtitle: "Stay ahead with daily, weekly, and monthly predictions based on your birth chart.",
      image: "/seasonal.png",
    },
  ];

  return (
    <Section id="features-section" className={classes.section}>
      <Container className={classes.container}>
        <Box textAlign="center" className={classes.sectionHeader}>
          <Typography variant="h2" className={classes.title}>Why align with Istrologer?</Typography>
        </Box>
        <Grid container={true} justifyContent="center" spacing={6}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={3} key={index}>
              <Box textAlign="center">
                <Box>
                  <img src={item.image} alt={item.title} className={classes.featureImage} />
                </Box>
                <Typography variant="h5" gutterBottom={true} className={classes.title1}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1" className={classes.text}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
